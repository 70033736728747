let host = ''
let apiUrl = '' // kpbase接口
let wApiUrl = '' // 微服务接口
let dApiUrl = '' // 独立接口

apiUrl = host + 'interface-server/api/'
wApiUrl = host
dApiUrl = host + 'interface-server/api/'




export {
    host,
    apiUrl,
    wApiUrl,
    dApiUrl
}
